.cardInner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipped .cardInner {
  transform: rotateY(180deg);
}

.cardFront,
.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.cardFront {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardBack {
  background-color: #f8f9fa;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

.card img {
  object-fit: cover;
  height: 17rem;
  width: 100%;
}

.cardBody {
  text-align: center;
}

.socialLinks {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
