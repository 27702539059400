.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 2.5em;

  .cardContainer {
    border: 1px solid #f0f0f0;
    border-radius: 1em;
    width: 100%;
  }

  .metadata {
    margin: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    line-height: 0.3rem;

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0;
    }

    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    .metacards {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 50%;
      padding: 1rem;
      border-radius: 1rem;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    }
  }
}
