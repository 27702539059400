.container {
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
  
  h1 {
    color: #2c3e50;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  p {
    color: #7f8c8d;
    font-size: 1.2rem;
  }
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.optionCard {
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: none;
  border-radius: 12px;
  overflow: hidden;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }

  .iconWrapper {
    background: linear-gradient(135deg, #3498db, #2980b9);
    color: white;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :global(.card-body) {
    padding: 1.5rem;
  }

  :global(.card-title) {
    color: #2c3e50;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  :global(.card-text) {
    color: #7f8c8d;
    font-size: 1rem;
    line-height: 1.5;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .header {
    margin-bottom: 2rem;
    
    h1 {
      font-size: 2rem;
    }
    
    p {
      font-size: 1rem;
    }
  }

  .cardContainer {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
