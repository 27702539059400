.appContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0px 0px 8px -3px rgba(0,0,0,0.3);
  border-bottom-left-radius:  1rem;
  border-bottom-right-radius:  1rem;
}


.headerContainer {
  .note {
    font-size: 1rem;
    font-weight: 500;
    color: red;
    text-align: center;
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 10px;
  .trafficContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: lighten($color: green, $amount: 10%);
    border-radius: 2rem;
    font-weight: bold;
    box-shadow: 0 0 40px rgba(0, 255, 0, 1);
    animation: pulse 1.5s infinite;
    
    @keyframes pulse {
      0% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(0, 255, 0, 1);
      }
      50% {
        transform: scale(1.1);
        box-shadow: 0 0 20px rgba(0, 100, 0, 1);
      }
      100% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(0, 50, 0, 1);
      }
    }
  }
  .activeUsers {
    margin: 0;
    font-weight: 700;
    color: #444;
  }

  @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;

      .note {
        text-align: justify;
      }
  }
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlayContent {
  background-color: white;
  padding: 2rem;
  text-align: center;
  border-radius: 10px;
  position: relative;
  width: 80%;
  max-width: 500px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.countdown {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}
