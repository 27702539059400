.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem; // Adjust the gap as needed
  padding: 0; // Remove default padding
  & h2 {
    margin-top: 1rem;
  }
}

@media (min-width: 1024px) {
  .container {
    justify-content: space-between;
  }
}

.cardWrapper {
  min-width: 18rem;
  max-width: 25rem;
}
