.assemblyContainer {
  padding: 0;
}

.layout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  flex-wrap: wrap;
  box-sizing: border-box;
  .seatsGrid {
    flex: 0 0 60%;
    max-width: 60%;
    display: grid;
    grid-template-columns: repeat(12, 1fr); // 12 columns
    gap: 5px;
    row-gap: 15px;
  }

  .legend {
    flex: 0 0 40%;
    max-width: calc(40% - 2rem);
    box-sizing: border-box;
  }
}

.seat {
  background-color: #ccc;
  border: 1px solid #333;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin: 2px;
  aspect-ratio: 1/1;
  position: relative; /* Needed for the overlay to position correctly */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  &:hover {
    cursor: pointer;
  }
}

.overlay {
  content: "";
  position: absolute; /* Position it over the image */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none; /* Ensure it doesn't interfere with user interactions */
  border-radius: 4px; /* Match border-radius with the image */
  opacity: 0.25; /* Adjust opacity to make the color overlay more subtle */
}

.elected {
  background-color: #28a745; // Green for elected seats
}

.other {
  background-color: #dc3545; // Red for other seats
}

/* Styling for the legend section */
.legend {
  padding: 1rem;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 0; /* Prevent the legend from shrinking on smaller screens */
}

.legend h4 {
  margin-bottom: 1rem;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.legendColor {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.elected {
  background-color: #28a745; // Green
}

.other {
  background-color: #dc3545; // Red
}

.legendFooter {
  small {
    font-weight: bold;
  }
}

.legendItem span {
  font-size: 0.875rem;
}

/* Responsive design for small screens */
@media (max-width: 768px) {
  .layout {
    display: block;
    .seatsGrid {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: 5px;
    }

    .legend {
      max-width: 100%;
      margin-top: 1rem;
    }
  }
}
