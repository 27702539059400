body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100vh;
}

.container .container {
  padding: 0;
}

nav.navbar {
  background-color: blueviolet;
}

nav.navbar a.navbar-brand,
nav div .navbar-nav a.nav-link {
  color: white;
}

nav.navbar a.navbar-brand:hover,
nav.navbar button.basic-navbar-nav .navbar-toggler-icon,
nav div .navbar-nav a.nav-link:hover {
  opacity: 0.8;
}

nav.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}

#disclaimer {
  bottom: 0;
  text-align: center;
  font-size: 9px;
}
