.appContainer {
  padding: 2rem;
  background-color: #f8f9fa;
  user-select: none;
}

.header {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
  font-size: 1.8rem;
}

@media (min-width: 1024px) {
  .header {
    text-align: left;
  }
}

.tableContainer {
  height: calc(100vh - 10rem);
  overflow: auto;
}

.table {
  min-width: 800px;
  overflow: scroll;
  th {
    font-weight: bold;
    font-size: 0.75rem;
    text-align: center;
  }
  td {
    vertical-align: middle;
    font-size: 0.7rem;
    text-align: center;
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 800;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  }

  tbody,
  thead {
    tr {
      td:first-child,
      th:first-child {
        font-weight: bold;
        box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.3);
        position: sticky;
        left: 0;
        border-right: 1px solid #888;
        text-align: left;
      }

      td:nth-child(2),
      th:nth-child(2) {
        text-align: left;
      }
    }
  }
}

.tableContainer {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 10; /* Ensure it covers the table */
  }
}
