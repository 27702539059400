
.cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flipped .cardInner {
    transform: rotateY(180deg);
  }
  
  .cardFront,
  .cardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .cardFront {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .cardBack {
    background-color: #f8f9fa;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
  }

  .contactInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      font-weight: 500;
      font-size: small;
    }

    .socialLinks {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      svg {
        font-size: 1rem;
        color: #6c757d;
        &:hover {
          color: #007bff;
          cursor: pointer;
        }
      }
    }
  }
  
  .card img {
    height: 17rem;
    width: 100%;
  }
  
  .cardBody {
    text-align: center;
  }
  