$imageSize: 40px;
.NVChartsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .NVPieChartContainer {
    width: 50%;
  }

  .NVBarGraphContainer {
    width: 100%;
  }
}

.runoffCandidates {
  position: relative;
  height: 3rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  img {
    position: absolute;
    top: calc(50% - calc($imageSize / 2));
    z-index: 1;
    width: $imageSize;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: $imageSize;
    opacity: 0; /* Start with invisible */
    transform: scale(0.8);
    animation: fadeInScale 3s forwards; /* Apply fade-in animation */
  }

  @media (min-width: 768px) {
    $imageSize: 50px;
    img {
      top: calc(50% - calc($imageSize / 2));
      z-index: 1;
      width: $imageSize;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      background-color: white;
      border-radius: $imageSize;
    }
  }

  .netumbo {
    left: 0;
  }

  .itula {
    right: 0;
  }
}

@keyframes fadeInScale {
  to {
    opacity: 1;
    transform: scale(1); /* Scale to normal size */
  }
}
