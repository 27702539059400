.teamSection {
    padding: 60px 0;
    background-color: #f8f9fa;
    text-align: center;
  }
  
  .sectionTitle {
    font-size: 2.5rem;
    margin-bottom: 40px;
    font-weight: 600;
  }
  
  .teamCard {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  
    .card-img-top {
      height: 250px;
      object-fit: cover;
    }
  
    .card-body {
      padding: 20px;
    }
  
    .btn-primary {
      background-color: #007bff;
      border-color: #007bff;
    }
  }
  