.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); // Semi-transparent overlay
    display: flex;
    align-items: center;
    justify-content: center;
    // needs to be high due to runoff tracker graphics
    z-index: 2;
  }
  
  .hidden {
    display: none; // Hide the overlay
  }
  
  .content {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    max-width: 600px;
    width: 90%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .winners {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .imageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .circleImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .description {
    margin-top: 10px;
    color: #666;
  }
  