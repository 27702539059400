// Define some variables for customization
$primary-color: #007bff;
$input-border-color: #ced4da;

.loginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;

  .loginCard {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h2 {
      text-align: center;
      margin-bottom: 1.5rem;
      color: $primary-color;
    }

    .form-control {
      border-color: $input-border-color;
      &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }

    .btn-primary {
      width: 100%;
      margin-top: 1rem;
    }

    .forgotPassword {
      text-align: center;
      margin-top: 1rem;
      a {
        color: $primary-color;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
