.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem; // Adjust the gap as needed
  padding: 0; // Remove default padding
}

@media (min-width: 1024px) {
  .container {
    justify-content: space-between;
  }
}

.cardWrapper {
  min-width: 18rem;
  max-width: 25rem;
}

// // Apply the pulsating golden border only to the first .cardWrapper
// .cardWrapper:first-of-type {
//   border: 5px solid transparent;  // Start with a transparent border
//   border-radius: 8px;  // Optional: Rounded corners
//   background-image: linear-gradient(white, white), radial-gradient(circle, gold, orange);  // Golden gradient for the border
//   background-origin: border-box;  // Apply the background to the border area
//   background-clip: content-box, border-box;  // Ensure the gradient is applied only to the border area
//   animation: pulseBorder 2s infinite;  // Apply the pulsating effect
// }

@keyframes pulseBorder {
  0% {
    box-shadow: 0 0 0 5px gold;
  }
  50% {
    box-shadow: 0 0 0 15px gold;
  }
  100% {
    box-shadow: 0 0 0 5px gold;
  }
}

@keyframes pulseBorder {
  0% {
    box-shadow: 0 0 0 5px gold;
  }
  50% {
    box-shadow: 0 0 0 15px gold;
  }
  100% {
    box-shadow: 0 0 0 5px gold;
  }
}
