.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 10rem;
    height: 10rem;
  }
}

.formGroup {
  margin-bottom: 0.4rem;
}

.formLabel {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.imageParent {
  position: relative;
}

.imageWrapper {
  position: fixed;
  top: 5rem;
}

.imageSide {
  max-width: calc(100vw - 55rem);
  max-height: calc(100vh - 10rem);
  z-index: 700;
}

.warningMessage {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.buttonRed {
  background-color: #ff0000 !important;
  color: white;
  border: none !important;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.buttonGreen {
  background-color: #4caf50 !important;
  color: white;
  border: none !important;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

#commento {
  display: none;
}

.pageWrapper {
  min-height: 100vh;
  background-color: #f5f6fa;
  padding: 2rem 0;
}

.mainContainer {
  max-width: 1800px;
  margin: 0 auto;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
  img {
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.2rem;
    color: #666;
  }
}

.formSection {
  padding: 0 1rem;
}

.formCard {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.formHeader {
  margin-bottom: 2rem;
  
  h2 {
    color: #2c3e50;
    margin-bottom: 1rem;
  }
}

.warningBanner {
  background: #fff3cd;
  color: #856404;
  padding: 0.75rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.warningMessage {
  background: #f8d7da;
  color: #721c24;
  padding: 0.75rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.formGroup {
  margin-bottom: 1rem;
  
  label {
    font-weight: 500;
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }
}

.select {
  :global(.select__control) {
    border-color: #ced4da;
    
    &:hover {
      border-color: #80bdff;
    }
  }
}

.tableWrapper {
  margin: 2rem 0;
  overflow-x: auto;
}

.votesTable {
  th {
    background: #f8f9fa;
    color: #2c3e50;
  }
  
  input[type="number"] {
    width: 120px;
  }
}

.mismatch {
  background-color: #ffebee !important;
  border-color: #ef5350 !important;
}

.formFooter {
  border-top: 1px solid #dee2e6;
  padding-top: 2rem;
  margin-top: 2rem;
}

.reviewerInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.submitButton, .archiveButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1.5rem;
  
  &:disabled {
    opacity: 0.7;
  }
}

.imageSection {
  position: relative;
}

.imageCard {
  position: fixed;
  top: 3rem;
  width: calc(41.666% - 2rem); // Adjusting for Col-5 width
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  
  img {
    width: 100%;
    height: calc(100vh - 8rem);
    object-fit: contain;
    background: #f8f9fa;
  }
}

.imageInfo {
  padding: 1rem;
  background: #f8f9fa;
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 991px) {
  .imageCard {
    position: static;
    width: 100%;
    margin-top: 2rem;
    
    img {
      height: auto;
      max-height: 600px;
    }
  }
}

@media (max-width: 768px) {
  .formGrid {
    grid-template-columns: 1fr;
  }
  
  .reviewerInfo {
    grid-template-columns: 1fr;
  }
  
  .actionButtons {
    flex-direction: column;
    
    button {
      width: 100%;
    }
  }
}

input[type="number"] {
  /* Remove only Firefox spinner */
  -moz-appearance: textfield;
}

.copyableText {
  cursor: pointer;
  transition: color 0.2s ease;
  
  &:hover {
    color: #4caf50;
  }
  
  &:active {
    color: #45a049;
  }
}

.toastContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.toast {
  background: #4caf50;
  color: white;
  min-width: 100px;
  text-align: center;
  
  :global(.toast-body) {
    padding: 0.5rem 1rem;
  }
}
